import { BaseViewModel } from '../../../common';
import { autoinject } from 'aurelia-framework';
import { rootState } from '../../../../reducers';
import { BlobService, EquipmentService } from '../../../../services';
import {
  isNone,
  isEmpty,
  emptyArray,
  userHasFeature,
  toISOString,
  ensureNumber,
  distinct, 
  saveBlob} from '../../../../utility';
import {
  IEquipment,
  IEquipmentAttachment,
  BlobTypes,
  AccessLevel
} from '../../../../interfaces';
import {
  equipmentParameterChanged,
  changeEquipmentAttachment,
  addEquipmentAttachment,
  changeAttachmentBlob,
  toggleExpandedAttachment,
  removeEquipmentAttachment,
  cancelEquipmentEditing
} from '../../../../actions';
import { getLogger } from 'aurelia-logging';
import { features, routeNames } from '../../../../config';
import { getSession } from '../../../../config/sessionService';
import './sitedetailsassets.css';
import { Router } from 'aurelia-router';
import { DateArray, EventAttributes, createEvent }  from 'ics';

interface ISiteDetailsAssetsState {
  isNew: boolean;
  isEditing: boolean;
  noCrudCommand: boolean;
  assets: IEquipment[];
  isLoading: boolean;
  equipmentId: number | undefined;
  currentlyEditingAsset?: Partial<IEquipment>;
  siteId: number;
  currentlyEditingAssetIsValid: boolean;
  canWrite: boolean;
  canDelete: boolean;
  canManageRestrictedAttachments: boolean;
  expandedAttachments: number[];
  hasAssets: boolean;
}

const isCurrentlyEditingAssetValid = (
  equipmentToEdit: Partial<IEquipment>
): boolean => {
  if (isEmpty(equipmentToEdit.name)) return false;

  if (isNone(equipmentToEdit.attachments)) return true;

  return !equipmentToEdit.attachments.some(a => !a.fileInfo);
};

@autoinject()
export class SiteDetailsAssets extends BaseViewModel<ISiteDetailsAssetsState> {

  constructor(
    private blobService: BlobService,
    private router: Router,
    private equipmentService: EquipmentService
  ) {    
    
    super(getLogger('SiteDetailsAssets'));        
  }

  assetTypes = [
    { name: 'Pump', value: 1 },
    { name: 'Controller', value: 2 },
    { name: 'Valve', value: 3 },
    { name: 'Tank', value: 4 },
    { name: 'Service', value: 5 },
    { name: 'Other', value: 6 },
    { name: 'Documentation', value: 7 },
    { name: 'EasyFeedRecipe', value: 8 }
  ];

  attachmentTypes = [
    { name: 'Document', value: 1 },
    { name: 'Script', value: 2 },
    { name: 'Report', value: 3 },
    { name: 'Quote', value: 4 },
    { name: 'Photo', value: 5 },
    { name: 'Other', value: 6 }
  ];
  
  getAssetTypeById = (id: number) => this.assetTypes.find(a => a.value === id);
  getAttachmentTypeById = (id: number) =>
    this.attachmentTypes.find(a => a.value === id);
  getShowControllerBackupViewerLink = (attachmentTypeId: number, attachmentDescription: string, fileName: string) => {
    // Determine whether an equipment attachment contains a controller backup
    if (attachmentTypeId !== 2) return false; // Should be of type 'Script'
    if (attachmentDescription && !attachmentDescription.includes('Configuration backup')) return false;
    if (fileName && !(fileName.endsWith('.bz2') || fileName.endsWith('.cfg'))) return false;
    return true;
  }

  async activate({
    action,
    equipmentId,
    id
  }: {
    id: string;
    action: 'edit' | 'create' | 'view' | undefined;
    equipmentId: string | undefined;
  }) {
    const siteId = ensureNumber(id);
    this.revealErrors = false;
    this.attachMapState(
      this.mapState(
        action,
        equipmentId ? ensureNumber(equipmentId) : undefined,
        siteId
      )
    );
  }

  mapState = (
    action: 'edit' | 'create' | 'view' | undefined,
    equipmentId: number | undefined,
    siteId: number
  ) => (state: rootState): ISiteDetailsAssetsState => {
    const assetsFetchers = this.equipmentService.getSiteEquipment(siteId);
    const assets = assetsFetchers.getEntityOrDefault(emptyArray);
    const isLoading = !assetsFetchers.isFetched();
    const selectedEquipment = isNone(equipmentId)
      ? undefined
      : assets.find(a => a.equipmentId === equipmentId);
    const distinctFunc = (attachment: IEquipmentAttachment) =>
      isNone(attachment.internalId)
        ? attachment.attachmentId
        : 'i_' + attachment.internalId;
    const currentlyEditingAsset = selectedEquipment
      ? {
          ...selectedEquipment,
          ...state.sitedetailsAsset.equipmentToEdit,
          attachments: distinct(
            [
              ...(state.sitedetailsAsset.equipmentToEdit.attachments || []),
              ...selectedEquipment.attachments
            ].filter(
              a =>
                !a.attachmentId ||
                !state.sitedetailsAsset.attachmentsToDelete.includes(
                  a.attachmentId
                )
            ),
            distinctFunc
          )
        }
      : {
          ...state.sitedetailsAsset.equipmentToEdit,
          attachments: [
            ...(state.sitedetailsAsset.equipmentToEdit.attachments || [])
          ]
        };
    return {
      isNew: action === 'create',
      isEditing: action === 'edit' || action === 'create',
      noCrudCommand: isNone(action),
      assets,
      currentlyEditingAsset,
      hasAssets: assets.length > 0,
      siteId,
      isLoading,
      equipmentId,
      expandedAttachments: state.sitedetailsAsset.expandedAttachments,
      currentlyEditingAssetIsValid: isCurrentlyEditingAssetValid(
        currentlyEditingAsset
      ),
      canWrite: userHasFeature(
        getSession(),
        features.siteEquipment,
        AccessLevel.Write
      ),
      canDelete: userHasFeature(
        getSession(),
        features.siteEquipment,
        AccessLevel.Delete
      ),
      canManageRestrictedAttachments: userHasFeature(
        getSession(),
        features.restrictedAttachments,
        AccessLevel.Read
      )
    };
  };

  selectEquipment({ equipmentId }: IEquipment) {
    if (this.state.isEditing) {
      this.dispatch(cancelEquipmentEditing());
    }
    this.router.navigateToRoute(routeNames.sitedetailsAssets, {
      equipmentId,
      action: 'view'
    });
  }

  attachmentsToPreview = ['png', 'jpg', 'jpeg', 'gif'];

  showThumbsForAttachment(attachment: IEquipmentAttachment) {
    const { fileInfo } = attachment;
    if (isNone(fileInfo)) return false;

    const { fileName } = fileInfo;
    if (isNone(fileName)) return false;

    return this.attachmentsToPreview.some(
      filetype => fileName.toLowerCase().indexOf(filetype) !== -1
    );
  }

  dispatchAssetDateChanged = (property: keyof IEquipment, value: Date) => 
    this.dispatchAssetChanged(
      property,
      toISOString(value, true)
    );

  dispatchAssetChanged(property: keyof IEquipment, value: string) {
    if (
      this.state.currentlyEditingAsset &&
      this.state.currentlyEditingAsset[property] === value
    )
      return;
    this.dispatch(equipmentParameterChanged({ property, value }));
  }

  dispatchAssetAttachmentChanged(
    attachment: IEquipmentAttachment,
    property: string,
    value: string
  ) {
    if (attachment && attachment[property] === value) return;
    this.dispatch(changeEquipmentAttachment({ attachment, property, value }));
  }

  generateIcal(asset: IEquipment, date: string, title: string = 'Equipment follow-up') {
        
    if (date === '') 
    {
      console.log('no date');  
      return;
    }      

    const reminderDate = new Date(date);
    const start: DateArray = [reminderDate.getFullYear() , reminderDate.getMonth()+1, reminderDate.getDate(), 8, 0];

    const event: EventAttributes = {
      start: start,
      startInputType: "local",
      duration: { hours: 1 },
      title: title,
      description: `Name: ${asset.name}\nModel: ${asset.model}\nManufacturer: ${asset.manufacturer}\nSerial: ${asset.serial}\nNotes: ${asset.notes}\n\nLink: https://telemetry.yara.com/sitedetails/${this.state.siteId}/assets/view/${asset.equipmentId}`,
      location: 'Yara Telemetry',
      url: `https://telemetry.yara.com/sitedetails/${this.state.siteId}/assets/view/${asset.equipmentId}`,
      categories: ['Yara Telemetry'],
      status: "CONFIRMED",
      // organizer: { name: 'Admin', email: 'Race@BolderBOULDER.com' },
      // attendees: [
      //   { name: 'Adam Gibbons', email: 'adam@example.com', rsvp: true, partstat: 'ACCEPTED', role: 'REQ-PARTICIPANT' },
      //   { name: 'Brittany Seaton', email: 'brittany@example2.org', dir: 'https://linkedin.com/in/brittanyseaton', role: 'OPT-PARTICIPANT' }
      // ]
    }

    createEvent(event, (error, value) => {
      if (error) {
        return;
      }

      const blob = new Blob([value], {
        type: 'text/calendar'
      });

      saveBlob(blob , "remider.ics");
    });        
  }

  confirmDeleteAsset: number | undefined = undefined;

  async deleteAsset(equipment: IEquipment) {
    await this.equipmentService.deleteSiteEquipment(
      this.state.siteId,
      equipment.equipmentId
    );
    this.confirmDeleteAsset = undefined;
    this.router.navigateToRoute(routeNames.sitedetailsAssets);
  }  

  revealErrors: boolean = false;

  async savePendingAsset(equipment: IEquipment) {
    if (!this.state.currentlyEditingAssetIsValid) {
      this.revealErrors = true;
      return;
    }
    const { equipmentId } = this.state.isNew
      ? await this.equipmentService.saveNewSiteEquipment(
          this.state.siteId,
          equipment
        )
      : await this.equipmentService.updateSiteEquipment(
          this.state.siteId,
          equipment
        );

    this.router.navigateToRoute(routeNames.sitedetailsAssets, {
      action: 'view',
      equipmentId
    });
  }

  createNewEquipment() {
    this.router.navigateToRoute(routeNames.sitedetailsAssets, {
      action: 'create'
    });
  }

  cancelAssetEdit() {
    this.dispatch(cancelEquipmentEditing());
    this.router.navigateBack();
  }

  addAttachment() {
    this.dispatch(addEquipmentAttachment());
  }

  toggleExpandAttachmentBar(attachment: IEquipmentAttachment) {
    this.dispatch(toggleExpandedAttachment(attachment));
  }

  downloadAttachment(attachment: IEquipmentAttachment) {
    if (isNone(attachment.fileInfo)) return;
    window.open(attachment.fileInfo.readUrl);
  }

  isExpanded = (
    attachment: IEquipmentAttachment,
    isExpandedArray: number[]
  ) => {
    const id = attachment.isNew
      ? attachment.internalId
      : attachment.attachmentId;
    return !isNone(isExpandedArray.find(a => a === id));
  };

  changeAttachmentBlob(event: any, attachment: IEquipmentAttachment) {
    const params = { attachment };
    this.blobService.uploadFileAndDispatch(
      event.target.files[0],
      BlobTypes.EquipmentAttachments,
      changeAttachmentBlob,
      params
    );
  }

  removeAttachment(attachment: IEquipmentAttachment) {
    this.dispatch(removeEquipmentAttachment(attachment));
  }

  goToEditModeOnAsset() {
    this.router.navigateToRoute(routeNames.sitedetailsAssets, {
      equipmentId: this.state.equipmentId,
      action: 'edit'
    });
  }

  detached() {
    this.dispatch(cancelEquipmentEditing());
    super.detached();
  }
}
